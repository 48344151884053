import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Radio,
  Button,
  Row,
  Col,
  Select,
  Tooltip,
  DatePicker,
  Checkbox,
  Upload,
  AutoComplete,
} from "antd";
import {
  InfoCircleOutlined,
  PlusOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import "./StudentFormModal.scss";

const { Option } = Select;

export default function StudentFormModal({
  visible,
  onClose,
  onSubmit,
  initialData,
  isEditing,
}) {
  const [currentTab, setCurrentTab] = useState("Basic Info");
  const [form] = Form.useForm();
  const [parents, setParents] = useState([]);
  const [studentData, setStudentData] = useState({});
  const [emergencyContacts, setEmergencyContacts] = useState([]);
  const [allergyNotes, setAllergyNotes] = useState([]);
  const [immunizationRecords, setImmunizationRecords] = useState([]);
  const [parentSearchResults, setParentSearchResults] = useState([]);

  useEffect(() => {
    if (visible && initialData) {
      // Populate the student state with initial data
      const updatedStudent = {
        studentId: initialData.student?.student_id || "",
        firstName: initialData.student?.first_name || "",
        lastName: initialData.student?.last_name || "",
        dateOfBirth: initialData.student?.date_of_birth
          ? dayjs(initialData.student.date_of_birth)
          : null,
        gender: initialData.student?.gender || "",
        photoPermission: initialData.student?.photo_permission || false,
        classroom: initialData.student?.class_name || "",
        status: initialData.student?.status ? "active" : "notActive",
        enrollmentDate: initialData.student?.enrollment_date
          ? dayjs(initialData.student.enrollment_date)
          : null,
        graduationDate: initialData.student?.graduation_date
          ? dayjs(initialData.student.graduation_date)
          : null,
        rotation: initialData.student?.rotation?.toLowerCase() || "",
        schedule: initialData.student?.schedule || [],
        address: initialData.student?.address_line1 || "",
        addressLine2: initialData.student?.address_line2 || "",
        city: initialData.student?.city || "",
        provinceState: initialData.student?.state || "",
        country: initialData.student?.country || "",
        postalCode: initialData.student?.zip_code || "",
        isSubsidized: initialData.student?.subsidy_status || false,
        parentFee: initialData.student?.parent_fee || "",
        subsidyFee: initialData.student?.subsidy_fee || "",
        cewlcFee: initialData.student?.cewlc_fee || "",
        profilePicture: initialData.student?.profile_picture || null,
      };

      setStudentData(updatedStudent);

      // Populate form with student state
      form.setFieldsValue(updatedStudent);

      // Set related data
      setParents(
        initialData.parents.map((parent) => ({
          id: parent.parent.parent_id,
          name: parent.parent.name,
          email: parent.parent.email,
          mobile: parent.parent.contact_number,
          mobile2: parent.parent.alternate_contact_number,
          relationship_id: parent.id,
          isPrimary: parent.parent.is_primary,
        }))
      );

      setEmergencyContacts(
        initialData.emergency_contacts.map((contact) => ({
          id: contact.id,
          name: contact.name,
          phone: contact.contact_number,
          relationship: contact.relationship,
        }))
      );

      setAllergyNotes(
        initialData.allergy_medical_records.map((record) => ({
          id: record.id,
          description: record.note,
          severity: record.severity,
        }))
      );

      setImmunizationRecords(
        initialData.student_records.map((record) => ({
          id: record.id,
          type: record.type,
          expiryDate: record.expiry_date ? dayjs(record.expiry_date) : null,
          label: record.label,
          fileList: record.file || null,
        }))
      );
    } else if (!visible) {
      // Reset form and state when modal is closed
      form.resetFields();
      setStudentData({
        firstName: "",
        lastName: "",
        dateOfBirth: null,
        gender: "",
        profilePicture: [],
        photoPermission: false,
        classroom: "",
        status: "notActive",
        enrollmentDate: null,
        graduationDate: null,
        rotation: "",
        schedule: [],
        address: "",
        addressLine2: "",
        city: "",
        provinceState: "",
        country: "",
        postalCode: "",
        isSubsidized: false,
        parentFee: "",
        subsidyFee: "",
        cewlcFee: "",
      });
      setParents([]);
      setEmergencyContacts([]);
      setAllergyNotes([]);
      setImmunizationRecords([]);
    } else {
      setStudentData({
        studentId: generateRandomId(),
        firstName: "",
        lastName: "",
        dateOfBirth: null,
        gender: "",
        profilePicture: [],
        photoPermission: false,
        classroom: "",
        status: "notActive",
        enrollmentDate: null,
        graduationDate: null,
        rotation: "",
        schedule: [],
        address: "",
        addressLine2: "",
        city: "",
        provinceState: "",
        country: "",
        postalCode: "",
        isSubsidized: false,
        parentFee: "",
        subsidyFee: "",
        cewlcFee: "",
      });
      setParents([]);
      setEmergencyContacts([]);
      setAllergyNotes([]);
      setImmunizationRecords([]);
    }
  }, [visible, initialData, form]);

  const handleTabChange = (tab) => {
    setCurrentTab(tab);
  };

  function generateRandomId() {
    return Math.random().toString(36).substr(2, 9); // Example: "a1b2c3d4e"
  }

  const handleSubmit = async () => {
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(",")[1]);
        reader.onerror = (error) => reject(error);
      });

    // Process Profile Picture
    let base64ProfilePicture = null;
    if (studentData.profilePicture?.[0]?.originFileObj) {
      // If a new profile picture is uploaded
      base64ProfilePicture = await toBase64(
        studentData.profilePicture[0].originFileObj
      );
    } else if (isEditing && studentData.profilePicture?.data) {
      // Preserve existing profile picture in edit mode
      base64ProfilePicture = arrayBufferToBase64(
        studentData.profilePicture.data
      );
    }

    // Process Immunization Records
    const immunizationFiles = await Promise.all(
      immunizationRecords.map(async (record) => {
        let base64File = null;

        if (record.fileList?.[0]?.originFileObj) {
          // If a new file is uploaded
          base64File = await toBase64(record.fileList[0].originFileObj);
        } else if (isEditing && record.fileList?.data) {
          // Preserve existing file in edit mode
          base64File = arrayBufferToBase64(record.fileList.data);
        }

        // Return the record with the base64-encoded file
        return {
          ...record,
          fileBase64: base64File,
        };
      })
    );

    // Prepare Data for Submission
    const formattedData = {
      studentData: {
        student_id: studentData.studentId || "",
        first_name: studentData.firstName || "",
        last_name: studentData.lastName || "",
        date_of_birth: studentData.dateOfBirth
          ? studentData.dateOfBirth.format("YYYY-MM-DD")
          : null,
        gender: studentData.gender || "",
        subsidy_status: studentData.isSubsidized || false,
        rotation: studentData.rotation || "",
        schedule: studentData.schedule || [],
        photo_permission: studentData.photoPermission || false,
        status: studentData.status === "active",
        enrollment_date: studentData.enrollmentDate
          ? studentData.enrollmentDate.format("YYYY-MM-DD")
          : null,
        graduation_date: studentData.graduationDate
          ? studentData.graduationDate.format("YYYY-MM-DD")
          : null,
        parent_fee: parseFloat(studentData.parentFee) || 0,
        subsidy_fee: parseFloat(studentData.subsidyFee) || 0,
        cewlc_fee: parseFloat(studentData.cewlcFee) || 0,
        class_name: studentData.classroom || "",
        address_line1: studentData.address || "",
        address_line2: studentData.addressLine2 || "",
        city: studentData.city || "",
        state: studentData.provinceState || "",
        zip_code: studentData.postalCode || "",
        country: studentData.country || "",
        profile_picture: base64ProfilePicture,
      },
      parents: parents.map((parent) => ({
        parent_id: parent.id,
        name: parent.name,
        contact_number: parent.mobile,
        alternate_contact_number: parent.mobile2,
        email: parent.email,
        relationship_id: parent.relationship_id,
        is_primary: parent.isPrimary,
      })),
      emergencyContacts: emergencyContacts.map((contact) => ({
        id: contact.id,
        student_id: studentData.studentId,
        name: contact.name,
        contact_number: contact.phone,
        relationship: contact.relationship,
      })),
      allergyMedicalData: allergyNotes.map((note) => ({
        id: note.id,
        student_id: studentData.studentId,
        note: note.description,
        severity: note.severity,
      })),
      studentRecords: immunizationFiles.map((record) => ({
        id: record.id,
        student_id: studentData.studentId,
        type: record.type,
        expiry_date: record.expiryDate
          ? record.expiryDate.format("YYYY-MM-DD")
          : null,
        label: record.label,
        file: record.fileBase64 || null,
      })),
    };

    console.log(formattedData);

    try {
      const response = isEditing
        ? await fetch(
            `https://api.montessorichildrenshouse.ca/update-student/${formattedData.studentData.student_id}`,
            {
              method: "PUT",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(formattedData),
            }
          )
        : await fetch("https://api.montessorichildrenshouse.ca/create-student", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(formattedData),
          });

      if (!response.ok) {
        throw new Error("Failed to submit the form");
      }

      const responseData = await response.json();
      console.log(
        `${isEditing ? "Update" : "Create"} successful:`,
        responseData
      );

      // Close the modal
      onClose();

      // Trigger a re-render or refresh of the parent component
      onSubmit();
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };

  const addParent = () => {
    setParents([
      ...parents,
      {
        id: generateRandomId(),
        relationship_id: generateRandomId(),
        name: "",
        email: "",
        mobile: "",
        mobile2: "",
        isPrimary: false,
      },
    ]);
  };

  const addEmergencyContact = () => {
    setEmergencyContacts([
      ...emergencyContacts,
      { id: generateRandomId(), name: "", phone: "", relationship: "" },
    ]);
  };

  const removeParent = (id) => {
    setParents(parents.filter((parent) => parent.id !== id));
  };

  const removeEmergencyContact = (id) => {
    setEmergencyContacts(
      emergencyContacts.filter((contact) => contact.id !== id)
    );
  };

  const addAllergyNote = () => {
    setAllergyNotes([
      ...allergyNotes,
      { id: generateRandomId(), description: "", severity: "" },
    ]);
  };

  const removeAllergyNote = (id) => {
    setAllergyNotes(allergyNotes.filter((note) => note.id !== id));
  };

  const addImmunizationRecord = () => {
    setImmunizationRecords([
      ...immunizationRecords,
      { id: generateRandomId(), type: "", expiryDate: null, label: "" },
    ]);
  };

  const removeImmunizationRecord = (id) => {
    setImmunizationRecords(
      immunizationRecords.filter((record) => record.id !== id)
    );
  };

  function arrayBufferToBase64(buffer) {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.length; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  const handleParentSearch = async (value) => {
    try {
      const response = await fetch(
        `https://api.montessorichildrenshouse.ca/search-parents?query=${value}`
      );
      const results = await response.json();

      // Ensure results is an array
      setParentSearchResults(Array.isArray(results) ? results : []);
    } catch (error) {
      console.error("Error fetching parent search results:", error);
      setParentSearchResults([]); // Reset to empty array on error
    }
  };

  const tabs = [
    "Basic Info",
    "Enrollment",
    "Contacts",
    "Address",
    "Records",
    "Fee Information",
  ];

  const renderTabContent = () => {
    switch (currentTab) {
      case "Basic Info":
        return (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="First Name">
                <Input
                  value={studentData.firstName || ""}
                  onChange={(e) =>
                    setStudentData({
                      ...studentData,
                      firstName: e.target.value,
                    })
                  }
                  placeholder="First"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Last Name">
                <Input
                  value={studentData.lastName || ""}
                  onChange={(e) =>
                    setStudentData({ ...studentData, lastName: e.target.value })
                  }
                  placeholder="Last"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Date of Birth">
                <DatePicker
                  format="DD-MM-YYYY"
                  value={studentData.dateOfBirth || null}
                  onChange={(date) =>
                    setStudentData({ ...studentData, dateOfBirth: date })
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Gender">
                <Radio.Group
                  value={studentData.gender || ""}
                  onChange={(e) =>
                    setStudentData({ ...studentData, gender: e.target.value })
                  }
                >
                  <Radio value="Male">Boy</Radio>
                  <Radio value="Female">Girl</Radio>
                  <Radio value="X">X</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Profile Picture">
                {isEditing && studentData.profilePicture?.data ? (
                  <div>
                    <img
                      src={`data:image/jpeg;base64,${arrayBufferToBase64(
                        studentData.profilePicture.data
                      )}`}
                      alt="Profile"
                      style={{ maxWidth: "100px", marginBottom: "10px" }}
                    />
                    <div>
                      <Button
                        type="primary"
                        danger
                        onClick={() =>
                          setStudentData({
                            ...studentData,
                            profilePicture: null,
                          })
                        }
                      >
                        Remove Picture
                      </Button>
                    </div>
                  </div>
                ) : (
                  <Upload
                    beforeUpload={() => false}
                    fileList={studentData.profilePicture || []}
                    onChange={(info) =>
                      setStudentData({
                        ...studentData,
                        profilePicture: info.fileList,
                      })
                    }
                    listType="picture"
                  >
                    <Button icon={<UploadOutlined />}>Choose File</Button>
                  </Upload>
                )}
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item>
                <Checkbox
                  checked={studentData.photoPermission || false}
                  onChange={(e) =>
                    setStudentData({
                      ...studentData,
                      photoPermission: e.target.checked,
                    })
                  }
                >
                  Permitted in photos and videos with other children
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
        );

      case "Enrollment":
        return (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Classroom">
                <Select
                  value={studentData.classroom || ""}
                  onChange={(value) =>
                    setStudentData({ ...studentData, classroom: value })
                  }
                  placeholder="Select a classroom"
                >
                  <Option value="Junior Casa 1">Junior Casa 1</Option>
                  <Option value="Junior Casa 2">Junior Casa 2</Option>
                  <Option value="Senior Casa">Senior Casa</Option>
                  <Option value="Toddlers">Toddlers</Option>
                  <Option value="Toddlers 2">Toddlers 2</Option>
                  <Option value="Waiting Room">Waiting Room</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Status">
                <Radio.Group
                  value={studentData.status || ""}
                  onChange={(e) =>
                    setStudentData({ ...studentData, status: e.target.value })
                  }
                >
                  <Radio value="active">Active</Radio>
                  <Radio value="notActive">Not Active</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Enrollment Date">
                <DatePicker
                  format="DD-MM-YYYY"
                  value={studentData.enrollmentDate || null}
                  onChange={(date) =>
                    setStudentData({ ...studentData, enrollmentDate: date })
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Graduation Date">
                <DatePicker
                  format="DD-MM-YYYY"
                  value={studentData.graduationDate || null}
                  onChange={(date) =>
                    setStudentData({ ...studentData, graduationDate: date })
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Rotation">
                <Radio.Group
                  value={studentData.rotation || ""}
                  onChange={(e) =>
                    setStudentData({ ...studentData, rotation: e.target.value })
                  }
                >
                  <Radio value="morning">Morning</Radio>
                  <Radio value="afternoon">Afternoon</Radio>
                  <Radio value="day">Day</Radio>
                  <Radio value="beforeAfterSchool">Before & After School</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Schedule">
                <Checkbox.Group
                  value={studentData.schedule || []}
                  onChange={(checkedValues) =>
                    setStudentData({ ...studentData, schedule: checkedValues })
                  }
                >
                  <Checkbox value="M">Mon</Checkbox>
                  <Checkbox value="T">Tue</Checkbox>
                  <Checkbox value="W">Wed</Checkbox>
                  <Checkbox value="R">Thu</Checkbox>
                  <Checkbox value="F">Fri</Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>
        );

      case "Contacts":
        return (
          <>
            <h3 className="section-header">
              PARENTS & GUARDIANS{" "}
              <Tooltip title="Add parent or guardian information">
                <InfoCircleOutlined style={{ color: "#FF8800" }} />
              </Tooltip>
            </h3>
            {parents.map((parent, index) => (
              <Row gutter={16} key={parent.id || index} className="parent-row">
                <Col span={8}>
                  <AutoComplete
                    allowClear
                    backfill
                    placeholder="Search or add parent"
                    value={parent.name || ""}
                    onSearch={handleParentSearch}
                    onSelect={(value) => {
                      const selectedParent = parentSearchResults.find(
                        (p) => p.parent_id === value
                      );
                      if (selectedParent) {
                        // Update the fields of the selected parent
                        setParents(
                          parents.map((p, i) =>
                            i === index
                              ? {
                                  ...p,
                                  id: selectedParent.parent_id,
                                  name: selectedParent.name,
                                  email: selectedParent.email,
                                  mobile: selectedParent.contact_number,
                                  mobile2:
                                    selectedParent.alternate_contact_number ||
                                    "",
                                  isPrimary: p.isPrimary || false, // Retain the isPrimary state
                                }
                              : p
                          )
                        );
                      }
                    }}
                    onChange={(value) => {
                      const selectedParent = parentSearchResults.find(
                        (p) => p.name === value
                      );
                      if (!selectedParent) {
                        // New parent input
                        setParents(
                          parents.map((p, i) =>
                            i === index
                              ? {
                                  ...p,
                                  id: parent.id || generateRandomId(),
                                  name: value,
                                  email: parent.email || "",
                                  mobile: parent.mobile || "",
                                  mobile2: parent.mobile2 || "",
                                  isPrimary: p.isPrimary || false,
                                }
                              : p
                          )
                        );
                      }
                    }}
                    options={
                      Array.isArray(parentSearchResults)
                        ? parentSearchResults.map((parent) => ({
                            value: parent.parent_id,
                            label: `${parent.name} (${
                              parent.email || "No Email"
                            })`,
                          }))
                        : []
                    }
                    filterOption={false}
                    style={{ width: "100%" }}
                  />
                </Col>

                <Col span={8}>
                  <Input
                    placeholder="Email"
                    value={parent.email || ""}
                    onChange={(e) =>
                      setParents(
                        parents.map((p, i) =>
                          i === index ? { ...p, email: e.target.value } : p
                        )
                      )
                    }
                  />
                </Col>
                <Col span={6}>
                  <Input
                    placeholder="Mobile Phone"
                    value={parent.mobile || ""}
                    onChange={(e) =>
                      setParents(
                        parents.map((p, i) =>
                          i === index ? { ...p, mobile: e.target.value } : p
                        )
                      )
                    }
                  />
                </Col>
                <Col span={4}>
                  <Checkbox
                    checked={!!parent.isPrimary}
                    onChange={(e) =>
                      setParents(
                        parents.map((p, i) =>
                          i === index
                            ? { ...p, isPrimary: e.target.checked }
                            : { ...p, isPrimary: false }
                        )
                      )
                    }
                  >
                    Primary
                  </Checkbox>
                </Col>
                <Col span={2}>
                  <Button
                    icon={<DeleteOutlined />}
                    type="text"
                    danger
                    onClick={() => removeParent(parent.id || index)}
                  />
                </Col>
              </Row>
            ))}
            <Button
              type="dashed"
              icon={<PlusOutlined />}
              onClick={() => {
                setParents((prevParents) => {
                  const newParent = {
                    id: generateRandomId(),
                    relationship_id: generateRandomId(),
                    name: "",
                    email: "",
                    mobile: "",
                    mobile2: "",
                    isPrimary: !isEditing && prevParents.length === 0, // Mark as primary only for the first parent if not editing
                  };

                  return [...prevParents, newParent];
                });
              }}
              className="add-parent-btn"
            >
              Add Parent / Guardian
            </Button>

            <h3 className="section-header" style={{ marginTop: "20px" }}>
              EMERGENCY CONTACTS{" "}
              <Tooltip title="Add emergency contact information">
                <InfoCircleOutlined style={{ color: "#FF8800" }} />
              </Tooltip>
            </h3>
            {emergencyContacts.map((contact) => (
              <Row
                gutter={16}
                key={contact.id}
                className="emergency-contact-row"
              >
                <Col span={8}>
                  <Input
                    placeholder="Name"
                    value={contact.name}
                    onChange={(e) =>
                      setEmergencyContacts(
                        emergencyContacts.map((c) =>
                          c.id === contact.id
                            ? { ...c, name: e.target.value }
                            : c
                        )
                      )
                    }
                  />
                </Col>
                <Col span={8}>
                  <Input
                    placeholder="Phone"
                    value={contact.phone}
                    onChange={(e) =>
                      setEmergencyContacts(
                        emergencyContacts.map((c) =>
                          c.id === contact.id
                            ? { ...c, phone: e.target.value }
                            : c
                        )
                      )
                    }
                  />
                </Col>
                <Col span={6}>
                  <Select
                    placeholder="Relationship"
                    value={contact.relationship}
                    onChange={(value) =>
                      setEmergencyContacts(
                        emergencyContacts.map((c) =>
                          c.id === contact.id
                            ? { ...c, relationship: value }
                            : c
                        )
                      )
                    }
                    style={{ width: "100%" }}
                  >
                    <Option value="Grandparent">Grandparent</Option>
                    <Option value="Parent/Guardian">Parent/Guardian</Option>
                    <Option value="Uncle/Aunt">Uncle/Aunt</Option>
                    <Option value="Friend">Friend</Option>
                    <Option value="Primary Healthcare Practitioner">
                      Primary Healthcare Practitioner
                    </Option>
                    <Option value="Other">Other</Option>
                  </Select>
                </Col>
                <Col span={2}>
                  <Button
                    icon={<DeleteOutlined />}
                    type="text"
                    danger
                    onClick={() => removeEmergencyContact(contact.id)}
                  />
                </Col>
              </Row>
            ))}
            <Button
              type="dashed"
              icon={<PlusOutlined />}
              onClick={addEmergencyContact}
              className="add-emergency-contact-btn"
            >
              Add Emergency Contact
            </Button>
          </>
        );
      case "Address":
        return (
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Address">
                <Input
                  value={studentData.address || ""}
                  onChange={(e) =>
                    setStudentData({ ...studentData, address: e.target.value })
                  }
                  placeholder="Street and number"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Address Line 2">
                <Input
                  value={studentData.addressLine2 || ""}
                  onChange={(e) =>
                    setStudentData({
                      ...studentData,
                      addressLine2: e.target.value,
                    })
                  }
                  placeholder="Apt, suite, etc"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="City">
                <Input
                  value={studentData.city || ""}
                  onChange={(e) =>
                    setStudentData({ ...studentData, city: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Province/State">
                <Input
                  value={studentData.provinceState || ""}
                  onChange={(e) =>
                    setStudentData({
                      ...studentData,
                      provinceState: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Country">
                <Select
                  value={studentData.country || ""}
                  onChange={(value) =>
                    setStudentData({ ...studentData, country: value })
                  }
                  placeholder="Select country"
                >
                  <Option value="USA">USA</Option>
                  <Option value="Canada">Canada</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Postal Code/ZIP">
                <Input
                  value={studentData.postalCode || ""}
                  onChange={(e) =>
                    setStudentData({
                      ...studentData,
                      postalCode: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        );

      case "Records":
        return (
          <>
            <h3 className="section-header">Allergy / Medical Notes</h3>
            {allergyNotes.map((note) => (
              <Row gutter={16} key={note.id} className="record-row">
                <Col span={8}>
                  <Input
                    placeholder="Allergy / Medical Note"
                    value={note.description}
                    onChange={(e) =>
                      setAllergyNotes(
                        allergyNotes.map((n) =>
                          n.id === note.id
                            ? { ...n, description: e.target.value }
                            : n
                        )
                      )
                    }
                  />
                </Col>
                <Col span={6}>
                  <Select
                    placeholder="Severity"
                    value={note.severity}
                    onChange={(value) =>
                      setAllergyNotes(
                        allergyNotes.map((n) =>
                          n.id === note.id ? { ...n, severity: value } : n
                        )
                      )
                    }
                    style={{ width: "100%" }}
                  >
                    <Option value="Minor">Minor</Option>
                    <Option value="Moderate">Moderate</Option>
                    <Option value="Severe">Severe</Option>
                    <Option value="Other">Other</Option>
                  </Select>
                </Col>
                <Col span={2}>
                  <Button
                    icon={<DeleteOutlined />}
                    type="text"
                    danger
                    onClick={() => removeAllergyNote(note.id)}
                  />
                </Col>
              </Row>
            ))}
            <Button
              type="dashed"
              icon={<PlusOutlined />}
              onClick={addAllergyNote}
              className="add-record-btn"
            >
              Add Allergy / Medical Note
            </Button>

            <h3 className="section-header" style={{ marginTop: "20px" }}>
              Immunization Records
            </h3>
            {immunizationRecords.map((record) => (
              <Row gutter={16} key={record.id} className="record-row">
                <Col span={6}>
                  <Select
                    placeholder="Record Type"
                    value={record.type}
                    onChange={(value) =>
                      setImmunizationRecords(
                        immunizationRecords.map((r) =>
                          r.id === record.id ? { ...r, type: value } : r
                        )
                      )
                    }
                    style={{ width: "100%" }}
                  >
                    <Option value="Immunizations">Immunizations</Option>
                    <Option value="Incident Report">Incident Report</Option>
                    <Option value="Registration">Registration</Option>
                    <Option value="Medical Consent">Medical Consent</Option>
                    <Option value="Permission Form">Permission Form</Option>
                    <Option value="Contact Form">Contact Form</Option>
                    <Option value="Emergency Contacts">
                      Emergency Contacts
                    </Option>
                    <Option value="Other">Other</Option>
                  </Select>
                </Col>
                <Col span={6}>
                  <DatePicker
                    placeholder="Expiry Date"
                    value={record.expiryDate}
                    onChange={(date) =>
                      setImmunizationRecords(
                        immunizationRecords.map((r) =>
                          r.id === record.id ? { ...r, expiryDate: date } : r
                        )
                      )
                    }
                    style={{ width: "100%" }}
                  />
                </Col>
                <Col span={6}>
                  <Input
                    placeholder="Record Label (Optional)"
                    value={record.label}
                    onChange={(e) =>
                      setImmunizationRecords(
                        immunizationRecords.map((r) =>
                          r.id === record.id
                            ? { ...r, label: e.target.value }
                            : r
                        )
                      )
                    }
                  />
                </Col>
                <Col span={4}>
                  {isEditing && record.fileList?.data ? (
                    <div>
                      <div style={{ marginBottom: "10px" }}>
                        <Button
                          type="link"
                          onClick={() => {
                            // Decode and create a Blob for download
                            const base64Data = arrayBufferToBase64(
                              record.fileList.data
                            );
                            const binaryData = atob(base64Data); // Decode base64 to binary
                            const byteArray = new Uint8Array(binaryData.length);

                            for (let i = 0; i < binaryData.length; i++) {
                              byteArray[i] = binaryData.charCodeAt(i);
                            }

                            // Use Blob with the assumed MIME type for PDF
                            const blob = new Blob([byteArray], {
                              type: "application/pdf",
                            });
                            const url = window.URL.createObjectURL(blob);

                            // Create an anchor element to trigger the download
                            const link = document.createElement("a");
                            link.href = url;
                            link.download = record.fileName || "file.pdf"; // Default to "file.pdf" if no file name is provided
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);

                            // Revoke the object URL
                            window.URL.revokeObjectURL(url);
                          }}
                        >
                          Download File
                        </Button>
                      </div>
                      <div>
                        <Button
                          type="primary"
                          danger
                          onClick={() =>
                            setImmunizationRecords(
                              immunizationRecords.map((r) =>
                                r.id === record.id
                                  ? { ...r, fileList: null }
                                  : r
                              )
                            )
                          }
                        >
                          Remove File
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <Upload
                      beforeUpload={() => false}
                      fileList={record.fileList || []}
                      onChange={(info) =>
                        setImmunizationRecords(
                          immunizationRecords.map((r) =>
                            r.id === record.id
                              ? { ...r, fileList: info.fileList }
                              : r
                          )
                        )
                      }
                      listType="text"
                    >
                      <Button icon={<UploadOutlined />}>
                        Choose File (PDF Only)
                      </Button>
                    </Upload>
                  )}
                </Col>

                <Col span={2}>
                  <Button
                    icon={<DeleteOutlined />}
                    type="text"
                    danger
                    onClick={() => removeImmunizationRecord(record.id)}
                  />
                </Col>
              </Row>
            ))}
            <Button
              type="dashed"
              icon={<PlusOutlined />}
              onClick={addImmunizationRecord}
              className="add-record-btn"
            >
              Add Immunization Record
            </Button>
          </>
        );
      case "Fee Information":
        return (
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item>
                <Checkbox
                  checked={studentData.isSubsidized || false}
                  onChange={(e) =>
                    setStudentData({
                      ...studentData,
                      isSubsidized: e.target.checked,
                    })
                  }
                >
                  Subsidized?
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Parent Fee">
                <Input
                  prefix="$"
                  value={studentData.parentFee || ""}
                  onChange={(e) =>
                    setStudentData({
                      ...studentData,
                      parentFee: e.target.value,
                    })
                  }
                  placeholder="Enter Parent Fee"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Subsidy Fee">
                <Input
                  prefix="$"
                  value={studentData.subsidyFee || ""}
                  onChange={(e) =>
                    setStudentData({
                      ...studentData,
                      subsidyFee: e.target.value,
                    })
                  }
                  placeholder="Enter Subsidy Fee"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="CEWLC Fee">
                <Input
                  prefix="$"
                  value={studentData.cewlcFee || ""}
                  onChange={(e) =>
                    setStudentData({ ...studentData, cewlcFee: e.target.value })
                  }
                  placeholder="Enter CEWLC Fee"
                />
              </Form.Item>
            </Col>
          </Row>
        );

      default:
        return null;
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      className="student-form-modal"
      width="95vw"
      style={{ top: 10, bottom: 10 }}
      //title={isEditing ? 'Edit Child Profile' : 'Add Child Profile'}
    >
      <div className="modal-header">
        <div className="modal-title-container">
          <h2 className="modal-title">
            {isEditing ? "Edit Child Profile" : "Add Child Profile"}
          </h2>
          <div className="modal-tabs">
            {tabs.map((tab) => (
              <button
                key={tab}
                className={`tab-btn ${currentTab === tab ? "active" : ""}`}
                onClick={() => handleTabChange(tab)}
              >
                {tab}
              </button>
            ))}
          </div>
        </div>
      </div>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        className="student-form"
        initialValues={{
          profilePicture: [],
        }}
      >
        {renderTabContent()}
        <div className="form-footer">
          <Button onClick={onClose} className="cancel-btn">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" className="save-btn">
            {isEditing ? "Update Child Data" : "Add New Child"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
