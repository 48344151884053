import React, { useState } from 'react';
import { Table, Button, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import StaffFormModal from '../StaffFormModal/StaffFormModal'; // Import the StaffFormModal
import './StaffList.scss';

export default function StaffList() {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Button type="text">Check In</Button>
      </Menu.Item>
      <Menu.Item key="2">
        <Button type="text">Edit Profile</Button>
      </Menu.Item>
      <Menu.Item key="3">
        <Button type="text">Daily Reports</Button>
      </Menu.Item>
      <Menu.Item key="4">
        <Button type="text">Schedule Absence</Button>
      </Menu.Item>
      <Menu.Item key="5">
        <Button type="text">Graduate (Remove from class)</Button>
      </Menu.Item>
    </Menu>
  );

  const columns = [
    { title: 'First Name', dataIndex: 'firstName', key: 'firstName' },
    { title: 'Last Name', dataIndex: 'lastName', key: 'lastName' },
    { title: 'Homeroom', dataIndex: 'homeroom', key: 'homeroom' },
    { title: 'Designation', dataIndex: 'designation', key: 'designation' },
    {
      title: 'Attendance',
      dataIndex: 'attendance',
      key: 'attendance',
      render: (status) => (status ? 'Checked In' : 'Not Checked In'),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Dropdown overlay={menu} trigger={['click']}>
          <Button type="link" className="actions-button" style={{ color: '#C9150E' }}>
            Actions <DownOutlined />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const data = [
    {
      key: '1',
      firstName: 'John',
      lastName: 'Doe',
      homeroom: 'Toddlers',
      designation: 'Teacher',
      attendance: true, // Checked In
    },
    {
      key: '2',
      firstName: 'Mary',
      lastName: 'Smith',
      homeroom: 'Junior Casa 1',
      designation: 'Assistant Teacher',
      attendance: false, // Not Checked In
    },
  ];

  return (
    <div className="staff-list-container">
      <div className="table-header">
        <h2 className="table-title">Staff List</h2>
        <Button
          type="primary"
          style={{ backgroundColor: '#C9150E', borderColor: '#C9150E' }}
          onClick={showModal} // Open modal on button click
        >
          Add Staff
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 5 }}
        bordered
        className="staff-list-table"
        scroll={{ x: 'max-content' }}
      />
      {/* Staff Form Modal */}
      <StaffFormModal visible={isModalVisible} onClose={closeModal} />
    </div>
  );
}
