import React, { useState } from 'react';
import { Modal, Form, Input, DatePicker, Radio, Select, Button, Checkbox, Upload, Row, Col } from 'antd';
import { UploadOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import './StaffFormModal.scss';

const { Option } = Select;

export default function StaffFormModal({ visible, onClose }) {
  const [currentTab, setCurrentTab] = useState('Basic Info');
  const [form] = Form.useForm();
  const [emergencyContacts, setEmergencyContacts] = useState([]);
  const [records, setRecords] = useState([]);
  const [notes, setNotes] = useState([]);
  const [certifications, setCertifications] = useState([]);

  const handleTabChange = (tab) => {
    setCurrentTab(tab);
  };

  const handleSubmit = (values) => {
    console.log('Form Values:', values);
  };

  const addEmergencyContact = () => {
    setEmergencyContacts([
      ...emergencyContacts,
      { id: Date.now(), name: '', phone: '', relationship: '' },
    ]);
  };

  const removeEmergencyContact = (id) => {
    setEmergencyContacts(emergencyContacts.filter((contact) => contact.id !== id));
  };

  const addRecord = () => {
    setRecords([
      ...records,
      { id: Date.now(), type: '', expiryDate: null, label: '' },
    ]);
  };

  const removeRecord = (id) => {
    setRecords(records.filter((record) => record.id !== id));
  };

  const addNote = () => {
    setNotes([
      ...notes,
      { id: Date.now(), content: '', type: '' },
    ]);
  };

  const removeNote = (id) => {
    setNotes(notes.filter((note) => note.id !== id));
  };

  const addCertification = () => {
    setCertifications([
      ...certifications,
      {
        id: Date.now(),
        type: '',
        expiryDate: null,
        noExpiryDate: false,
        note: '',
      },
    ]);
  };

  const removeCertification = (id) => {
    setCertifications(certifications.filter((cert) => cert.id !== id));
  };

  const tabs = [
    'Basic Info',
    'Enrollment',
    'Contacts',
    'Address',
    'Records',
    'Additional Notes',
    'Certifications',
  ];

  const renderTabContent = () => {
    switch (currentTab) {
      case 'Basic Info':
        return (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="firstName" label="First Name" rules={[{ required: true }]}>
                <Input placeholder="First" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="lastName" label="Last Name" rules={[{ required: true }]}>
                <Input placeholder="Last" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email' }]}>
                <Input placeholder="Email" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="phone" label="Phone" rules={[{ required: true }]}>
                <Input placeholder="Phone" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="dateOfBirth" label="Date of Birth" rules={[{ required: true }]}>
                <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="gender" label="Gender" rules={[{ required: true }]}>
                <Radio.Group>
                  <Radio value="male">Male</Radio>
                  <Radio value="female">Female</Radio>
                  <Radio value="x">X</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="profilePicture" label="Profile Picture">
                <Upload>
                  <Button icon={<UploadOutlined />}>Choose File</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        );
      case 'Enrollment':
        return (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="homeroom" label="Homeroom" rules={[{ required: true }]}>
                <Select placeholder="Select a homeroom">
                  <Option value="juniorCasa1">Junior Casa 1</Option>
                  <Option value="juniorCasa2">Junior Casa 2</Option>
                  <Option value="seniorCasa">Senior Casa</Option>
                  <Option value="noHomeroom">No Homeroom</Option>
                  <Option value="toddlers">Toddlers</Option>
                  <Option value="toddlers2">Toddlers 2</Option>
                  <Option value="waitingRoom">Waiting Room</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="status" label="Status" rules={[{ required: true }]}>
                <Radio.Group>
                  <Radio value="active">Active</Radio>
                  <Radio value="notActive">Not Active</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="startDate" label="Start Date" rules={[{ required: true }]}>
                <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="endDate" label="End Date">
                <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="rotation" label="Rotation">
                <Radio.Group>
                  <Radio value="morning">Morning</Radio>
                  <Radio value="afternoon">Afternoon</Radio>
                  <Radio value="day">Day</Radio>
                  <Radio value="beforeAfterSchool">Before & After School</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="schedule" label="Schedule">
                <Checkbox.Group>
                  <Checkbox value="mon">Mon</Checkbox>
                  <Checkbox value="tue">Tue</Checkbox>
                  <Checkbox value="wed">Wed</Checkbox>
                  <Checkbox value="thu">Thu</Checkbox>
                  <Checkbox value="fri">Fri</Checkbox>
                  <Checkbox value="sat">Sat</Checkbox>
                  <Checkbox value="sun">Sun</Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>
        );
      case 'Contacts':
        return (
          <>
            <h3 className="section-header">Emergency Contacts</h3>
            {emergencyContacts.map((contact) => (
              <Row gutter={16} key={contact.id} className="contact-row">
                <Col span={8}>
                  <Input
                    placeholder="Name"
                    value={contact.name}
                    onChange={(e) =>
                      setEmergencyContacts(
                        emergencyContacts.map((c) =>
                          c.id === contact.id ? { ...c, name: e.target.value } : c
                        )
                      )
                    }
                  />
                </Col>
                <Col span={8}>
                  <Input
                    placeholder="Phone"
                    value={contact.phone}
                    onChange={(e) =>
                      setEmergencyContacts(
                        emergencyContacts.map((c) =>
                          c.id === contact.id ? { ...c, phone: e.target.value } : c
                        )
                      )
                    }
                  />
                </Col>
                <Col span={6}>
                  <Select
                    placeholder="Relationship"
                    value={contact.relationship}
                    onChange={(value) =>
                      setEmergencyContacts(
                        emergencyContacts.map((c) =>
                          c.id === contact.id ? { ...c, relationship: value } : c
                        )
                      )
                    }
                    style={{ width: '100%' }}
                  >
                    <Option value="Spouse">Spouse</Option>
                    <Option value="Parent/Guardian">Parent/Guardian</Option>
                    <Option value="Family Member">Family Member</Option>
                    <Option value="Friend">Friend</Option>
                    <Option value="Other">Other</Option>
                  </Select>
                </Col>
                <Col span={2}>
                  <Button
                    icon={<DeleteOutlined />}
                    type="text"
                    danger
                    onClick={() => removeEmergencyContact(contact.id)}
                  />
                </Col>
              </Row>
            ))}
            <Button
              type="dashed"
              icon={<PlusOutlined />}
              onClick={addEmergencyContact}
              className="add-contact-btn"
            >
              Add Emergency Contact
            </Button>
          </>
        );
      case 'Records':
        return (
          <>
            <h3 className="section-header">Records</h3>
            {records.map((record) => (
              <Row gutter={16} key={record.id} className="record-row">
                <Col span={6}>
                  <Select
                    placeholder="Record Type"
                    value={record.type}
                    onChange={(value) =>
                      setRecords(
                        records.map((r) =>
                          r.id === record.id ? { ...r, type: value } : r
                        )
                      )
                    }
                    style={{ width: '100%' }}
                  >
                    <Option value="Immunizations">Immunizations</Option>
                    <Option value="Degree">Degree</Option>
                    <Option value="Other">Other</Option>
                  </Select>
                </Col>
                <Col span={6}>
                  <DatePicker
                    placeholder="Expiry Date"
                    value={record.expiryDate}
                    onChange={(date) =>
                      setRecords(
                        records.map((r) =>
                          r.id === record.id ? { ...r, expiryDate: date } : r
                        )
                      )
                    }
                    style={{ width: '100%' }}
                  />
                </Col>
                <Col span={6}>
                  <Input
                    placeholder="Record Label (Optional)"
                    value={record.label}
                    onChange={(e) =>
                      setRecords(
                        records.map((r) =>
                          r.id === record.id ? { ...r, label: e.target.value } : r
                        )
                      )
                    }
                  />
                </Col>
                <Col span={4}>
                  <Upload>
                    <Button icon={<UploadOutlined />}>Upload File</Button>
                  </Upload>
                </Col>
                <Col span={2}>
                  <Button
                    icon={<DeleteOutlined />}
                    type="text"
                    danger
                    onClick={() => removeRecord(record.id)}
                  />
                </Col>
              </Row>
            ))}
            <Button
              type="dashed"
              icon={<PlusOutlined />}
              onClick={addRecord}
              className="add-record-btn"
            >
              Add Record
            </Button>
          </>
        );
      case 'Additional Notes':
        return (
          <>
            <h3 className="section-header">Additional Notes</h3>
            {notes.map((note) => (
              <Row gutter={16} key={note.id} className="note-row">
                <Col span={16}>
                  <Input
                    placeholder="Add Note"
                    value={note.content}
                    onChange={(e) =>
                      setNotes(
                        notes.map((n) =>
                          n.id === note.id ? { ...n, content: e.target.value } : n
                        )
                      )
                    }
                  />
                </Col>
                <Col span={6}>
                  <Select
                    placeholder="Note Type"
                    value={note.type}
                    onChange={(value) =>
                      setNotes(
                        notes.map((n) =>
                          n.id === note.id ? { ...n, type: value } : n
                        )
                      )
                    }
                    style={{ width: '100%' }}
                  >
                    <Option value="Special Instructions">Special Instructions</Option>
                    <Option value="Schedule">Schedule</Option>
                    <Option value="Favorite Things">Favorite Things</Option>
                    <Option value="Other">Other</Option>
                  </Select>
                </Col>
                <Col span={2}>
                  <Button
                    icon={<DeleteOutlined />}
                    type="text"
                    danger
                    onClick={() => removeNote(note.id)}
                  />
                </Col>
              </Row>
            ))}
            <Button
              type="dashed"
              icon={<PlusOutlined />}
              onClick={addNote}
              className="add-note-btn"
            >
              Add Profile Note
            </Button>
          </>
        );
      case 'Certifications':
        return (
          <>
            <h3 className="section-header">Certifications</h3>
            {certifications.map((cert) => (
              <Row gutter={16} key={cert.id} className="certification-row">
                <Col span={6}>
                  <Select
                    placeholder="Type of Record/Certificate"
                    value={cert.type}
                    onChange={(value) =>
                      setCertifications(
                        certifications.map((c) =>
                          c.id === cert.id ? { ...c, type: value } : c
                        )
                      )
                    }
                    style={{ width: '100%' }}
                  >
                    <Option value="CPR">CPR</Option>
                    <Option value="RECE">RECE</Option>
                    <Option value="COVID-19 Documentation">COVID-19 Documentation</Option>
                  </Select>
                </Col>
                <Col span={6}>
                  <DatePicker
                    placeholder="Expiry Date"
                    value={cert.expiryDate}
                    onChange={(date) =>
                      setCertifications(
                        certifications.map((c) =>
                          c.id === cert.id ? { ...c, expiryDate: date } : c
                        )
                      )
                    }
                    style={{ width: '100%' }}
                  />
                </Col>
                <Col span={4}>
                  <Checkbox
                    checked={cert.noExpiryDate}
                    onChange={(e) =>
                      setCertifications(
                        certifications.map((c) =>
                          c.id === cert.id
                            ? { ...c, noExpiryDate: e.target.checked }
                            : c
                        )
                      )
                    }
                  >
                    No Expiry Date
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <Input
                    placeholder="Note (Optional)"
                    value={cert.note}
                    onChange={(e) =>
                      setCertifications(
                        certifications.map((c) =>
                          c.id === cert.id ? { ...c, note: e.target.value } : c
                        )
                      )
                    }
                  />
                </Col>
                <Col span={2}>
                  <Button
                    icon={<DeleteOutlined />}
                    type="text"
                    danger
                    onClick={() => removeCertification(cert.id)}
                  />
                </Col>
              </Row>
            ))}
            <Button
              type="dashed"
              icon={<PlusOutlined />}
              onClick={addCertification}
              className="add-certification-btn"
            >
              Add Certification
            </Button>
          </>
        );
      case 'Address':
        return (
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="address" label="Address" rules={[{ required: true }]}>
                <Input placeholder="Street and number" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="addressLine2" label="Address Line 2">
                <Input placeholder="Apt, suite, etc" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="city" label="City" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="provinceState" label="Province/State" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="country" label="Country" rules={[{ required: true }]}>
                <Select placeholder="Select country">
                  <Option value="USA">USA</Option>
                  <Option value="Canada">Canada</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="postalCode" label="Postal Code/ZIP" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      className="staff-form-modal"
      width="90vw"
      style={{ top: 10 }}
    >
      <div className="modal-header">
        <div className="modal-title-container">
          <h2 className="modal-title">Add Teacher Profile</h2>
          <div className="modal-tabs">
            {tabs.map((tab) => (
              <button
                key={tab}
                className={`tab-btn ${currentTab === tab ? 'active' : ''}`}
                onClick={() => handleTabChange(tab)}
              >
                {tab}
              </button>
            ))}
          </div>
        </div>
      </div>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        className="staff-form"
      >
        {renderTabContent()}
        <div className="form-footer">
          <Button onClick={onClose} className="cancel-btn">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" className="save-btn">
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
