import React, { useState, useEffect } from 'react'; // React hooks
import { Table, Button, Dropdown, Menu } from 'antd'; // Ant Design components
import { DownOutlined } from '@ant-design/icons'; // Ant Design icons
import StudentFormModal from '../StudentFormModal/StudentFormModal'; // Your modal component
import './StudentList.scss'; // Styles for the component


const StudentList = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    fetchStudents();
  }, []);

  const fetchStudents = async () => {
    try {
      setLoading(true);
      const response = await fetch('https://api.montessorichildrenshouse.ca/students');
      if (!response.ok) {
        throw new Error('Failed to fetch students');
      }
      const data = await response.json();
      const transformedData = data.map(item => ({
        key: item.student.student_id,
        firstName: item.student.first_name,
        lastName: item.student.last_name,
        enrollmentDate: new Date(item.student.enrollment_date).toLocaleDateString(),
        dateOfBirth: new Date(item.student.date_of_birth).toLocaleDateString(),
        subsidyStatus: item.student.subsidy_status,
        parentFee: parseFloat(item.student.parent_fee),
        rotation: item.student.rotation,
        className: item.student.class_name,
        status: item.student.status,
        student_id: item.student.student_id,
        rawData: item // Store the complete raw data for editing
      }));
      setStudents(transformedData);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEditProfile = async (studentId) => {
    try {
      setLoading(true);
      const response = await fetch(`https://api.montessorichildrenshouse.ca/students/${studentId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch student details');
      }
      const data = await response.json();
      setSelectedStudent(data);
      setIsEditing(true);
      setIsModalVisible(true);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const getActionMenu = (record) => (
    <Menu>
      <Menu.Item key="1">
        <Button type="text">Check In</Button>
      </Menu.Item>
      <Menu.Item key="2">
        <Button type="text" onClick={() => handleEditProfile(record.student_id)}>
          Edit Profile
        </Button>
      </Menu.Item>
      <Menu.Item key="3">
        <Button type="text">Daily Reports</Button>
      </Menu.Item>
      <Menu.Item key="4">
        <Button type="text">Schedule Absence</Button>
      </Menu.Item>
      <Menu.Item key="5">
        <Button type="text">Graduate (Remove from class)</Button>
      </Menu.Item>
    </Menu>
  );

  const columns = [
    { title: 'First Name', dataIndex: 'firstName', key: 'firstName' },
    { title: 'Last Name', dataIndex: 'lastName', key: 'lastName' },
    { title: 'Class', dataIndex: 'className', key: 'className' },
    { title: 'Enrollment Date', dataIndex: 'enrollmentDate', key: 'enrollmentDate' },
    { title: 'Date of Birth', dataIndex: 'dateOfBirth', key: 'dateOfBirth' },
    { 
      title: 'Subsidy Status', 
      dataIndex: 'subsidyStatus', 
      key: 'subsidyStatus',
      render: (status) => (status ? 'Yes' : 'No')
    },
    {
      title: 'Parent Fee',
      dataIndex: 'parentFee',
      key: 'parentFee',
      render: (fee) => `$${fee.toFixed(2)}`,
    },
    { title: 'Rotation', dataIndex: 'rotation', key: 'rotation' },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <span style={{ 
          padding: '4px 8px', 
          borderRadius: '4px',
          backgroundColor: status ? '#f6ffed' : '#fff1f0',
          color: status ? '#52c41a' : '#f5222d'
        }}>
          {status ? 'Active' : 'Inactive'}
        </span>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Dropdown overlay={getActionMenu(record)} trigger={['click']}>
          <Button type="link" className="actions-button" style={{ color: '#C9150E' }}>
            Actions <DownOutlined />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedStudent(null);
    setIsEditing(false);
  };

  return (
    <div className="student-list-container">
      <div className="table-header">
        <h2 className="table-title">Student List</h2>
        <Button
          type="primary"
          style={{ backgroundColor: '#C9150E', borderColor: '#C9150E' }}
          onClick={() => {
            setIsEditing(false);
            setSelectedStudent(null);
            setIsModalVisible(true);
          }}
        >
          Add Student
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={students}
        loading={loading}
        pagination={{ pageSize: 5 }}
        bordered
        className="student-list-table"
        scroll={{ x: 'max-content' }}
      />

      <StudentFormModal
        visible={isModalVisible}
        onClose={handleModalClose}
        onSubmit={fetchStudents} // Directly refresh the student list
        initialData={selectedStudent}
        isEditing={isEditing}
      />
    </div>
  );
};

export default StudentList;
