import React, { useState, useEffect } from 'react';
import { Table, Avatar, Button, Dropdown, Menu, Space, Spin, message } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import axios from 'axios';
import './ClassList.scss';

export default function ClassList({ className = '' }) {
  const [loading, setLoading] = useState(true);
  const [staffData, setStaffData] = useState([]);
  const [studentData, setStudentData] = useState([]);

  function arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.length; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  const fetchClassroomData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`https://api.montessorichildrenshouse.ca/classroom/${className}`);
      const { staff, students } = response.data;

      const formattedStaff = staff.map((member) => {
        const profilePicture = member.profile_picture?.data
          ? `data:image/jpeg;base64,${arrayBufferToBase64(member.profile_picture.data)}`
          : 'https://via.placeholder.com/50';

        return {
          key: member.staff_id,
          staff_id: member.staff_id,
          profilePicture,
          name: `${member.first_name} ${member.last_name}`,
          check_in_status: member.check_in_status,
          check_out_time: member.check_out_time,
        };
      });

      const formattedStudents = students.map((student) => {
        const profilePicture = student.profile_picture?.data
          ? `data:image/jpeg;base64,${arrayBufferToBase64(student.profile_picture.data)}`
          : 'https://via.placeholder.com/50';

        return {
          key: student.student_id,
          student_id: student.student_id,
          profilePicture,
          name: `${student.first_name} ${student.last_name}`,
          check_in_status: student.check_in_status,
          check_out_time: student.check_out_time,
        };
      });

      setStaffData(formattedStaff);
      setStudentData(formattedStudents);
    } catch (error) {
      console.error('Error fetching classroom data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClassroomData();
  }, [className]);

  const handleStaffClock = async (staff_id, isCheckedIn) => {
    try {
      const url = isCheckedIn
        ? 'https://api.montessorichildrenshouse.ca/staff/clock-out'
        : 'https://api.montessorichildrenshouse.ca/staff/clock-in';

      await axios.post(url, { staff_id });
      message.success(
        `Staff ${staff_id} ${isCheckedIn ? 'clocked out' : 'clocked in'} successfully!`
      );

      fetchClassroomData();
    } catch (error) {
      console.error(`Error ${isCheckedIn ? 'clocking out' : 'clocking in'} staff:`, error);
      message.error(
        error.response?.data?.message || `Failed to ${isCheckedIn ? 'clock out' : 'clock in'} staff.`
      );
    }
  };

  const handleStudentCheck = async (student_id, isCheckedIn) => {
    try {
      const url = isCheckedIn
        ? 'https://api.montessorichildrenshouse.ca/student/check-out'
        : 'https://api.montessorichildrenshouse.ca/student/check-in';

      await axios.post(url, { student_id });
      message.success(
        `Student ${student_id} ${isCheckedIn ? 'checked out' : 'checked in'} successfully!`
      );

      fetchClassroomData();
    } catch (error) {
      console.error(`Error ${isCheckedIn ? 'checking out' : 'checking in'} student:`, error);
      message.error(
        error.response?.data?.message ||
          `Failed to ${isCheckedIn ? 'check out' : 'check in'} student.`
      );
    }
  };

  const formatCheckOutTime = (time) => {
    if (!time) return '';
    const date = new Date(time);
    date.setHours(date.getHours() - 5); // Adjust 5 hours back
    return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
  };

  const staffColumns = [
    {
      dataIndex: 'profilePicture',
      key: 'profilePicture',
      width: 60,
      render: (profilePicture) => <Avatar src={profilePicture} alt="Profile" />,
    },
    {
      dataIndex: 'name',
      key: 'name',
    },
    {
      key: 'actions',
      render: (_, record) => (
        <Space style={{ justifyContent: 'flex-end', width: '100%' }}>
          {record.check_in_status === 'checked_out' ? (
            <Button type="default" disabled>
              Checked out at {formatCheckOutTime(record.check_out_time)}
            </Button>
          ) : (
            <>
              <Dropdown overlay={<Menu />} trigger={['click']}>
                <Button type="link" className="actions-button">
                  Actions <DownOutlined />
                </Button>
              </Dropdown>
              <Button
                type="primary"
                className="clock-in-button"
                onClick={() =>
                  handleStaffClock(record.staff_id, record.check_in_status === 'checked_in')
                }
              >
                {record.check_in_status === 'checked_in' ? 'Clock Out' : 'Clock In'}
              </Button>
            </>
          )}
        </Space>
      ),
    },
  ];

  const studentColumns = [
    {
      dataIndex: 'profilePicture',
      key: 'profilePicture',
      width: 60,
      render: (profilePicture) => <Avatar src={profilePicture} alt="Profile" />,
    },
    {
      dataIndex: 'name',
      key: 'name',
    },
    {
      key: 'actions',
      render: (_, record) => (
        <Space style={{ justifyContent: 'flex-end', width: '100%' }}>
          {record.check_in_status === 'checked_out' ? (
            <Button type="default" disabled>
              Checked out at {formatCheckOutTime(record.check_out_time)}
            </Button>
          ) : (
            <>
              <Dropdown overlay={<Menu />} trigger={['click']}>
                <Button type="link" className="actions-button">
                  Actions <DownOutlined />
                </Button>
              </Dropdown>
              <Button
                type="primary"
                className="check-in-button"
                onClick={() =>
                  handleStudentCheck(record.student_id, record.check_in_status === 'checked_in')
                }
              >
                {record.check_in_status === 'checked_in' ? 'Check Out' : 'Check In'}
              </Button>
            </>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div className={`class-list-container ${className}`}>
      <h2>Classroom: {className}</h2>

      {loading ? (
        <Spin size="large" />
      ) : (
        <>
          <div className="table-section">
            <div className="table-header">
              <h3 className="section-title">Staff</h3>
            </div>
            <Table
              columns={staffColumns}
              dataSource={staffData}
              pagination={false}
              className="class-table"
              showHeader={false}
              scroll={{ x: 'max-content' }}
            />
          </div>

          <div className="table-section">
            <div className="table-header">
              <h3 className="section-title">Students</h3>
            </div>
            <Table
              columns={studentColumns}
              dataSource={studentData}
              pagination={false}
              className="class-table"
              showHeader={false}
              scroll={{ x: 'max-content' }}
            />
          </div>
        </>
      )}
    </div>
  );
}
