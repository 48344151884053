import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import {
  HomeOutlined,
  TeamOutlined,
  UserOutlined,
  BookOutlined,
  IdcardOutlined,
  DollarOutlined,
} from '@ant-design/icons';
import './Dashboard.scss';
import StudentList from '../../Components/StudentList/StudentList';
import StaffList from '../../Components/StaffList/StaffList';
import ClassList from '../../Components/ClassList/ClassList';
import Finance from '../../Components/Finance/Finance';

const { Sider, Content } = Layout;
const { SubMenu } = Menu;

export default function Dashboard() {
  const [selectedView, setSelectedView] = useState('students'); // Default view is "Students"
  const [selectedClass, setSelectedClass] = useState(''); // Holds the selected class name

  const renderContent = () => {
    switch (selectedView) {
      case 'students':
        return <StudentList />;
      case 'staff':
        return <StaffList />;
      case 'class':
        return <ClassList className={selectedClass} />;
      case 'finance':
        return <Finance />;
      default:
        return <div>Welcome to the Dashboard!</div>; // Default content
    }
  };

  return (
    <Layout className="dashboard-layout">
      <Sider className="dashboard-sider">
        <div className="sider-logo">
          <img
            src={require('../../Assets/Images/montessori-logo.jpg')}
            alt="Montessori Logo"
            className="logo-image"
          />
        </div>
        <Menu
          mode="inline"
          defaultSelectedKeys={['3']}
          className="dashboard-menu"
          onClick={({ key }) => {
            if (key === '3') setSelectedView('students');
            if (key === '5') setSelectedView('staff');
            if (key === 'finance') setSelectedView('finance');

            // Handle class selection
            if (key.startsWith('2-')) {
              setSelectedView('class');
              switch (key) {
                case '2-1':
                  setSelectedClass('Toddlers');
                  break;
                case '2-2':
                  setSelectedClass('Toddlers 2');
                  break;
                case '2-3':
                  setSelectedClass('Junior Casa 1');
                  break;
                case '2-4':
                  setSelectedClass('Junior Casa 2');
                  break;
                case '2-5':
                  setSelectedClass('Senior Casa');
                  break;
                case '2-6':
                  setSelectedClass('Waiting Room');
                  break;
                default:
                  setSelectedClass('');
              }
            }
          }}
        >
          <Menu.Item key="1" icon={<HomeOutlined />}>
            Home
          </Menu.Item>
          <SubMenu
            key="2"
            icon={<BookOutlined />}
            title="Classrooms"
            className="submenu-classrooms"
          >
            <Menu.Item key="2-1">Toddlers</Menu.Item>
            <Menu.Item key="2-2">Toddlers 2</Menu.Item>
            <Menu.Item key="2-3">Junior Casa 1</Menu.Item>
            <Menu.Item key="2-4">Junior Casa 2</Menu.Item>
            <Menu.Item key="2-5">Senior Casa</Menu.Item>
            <Menu.Item key="2-6">Waiting Room</Menu.Item>
          </SubMenu>
          <Menu.Item key="3" icon={<TeamOutlined />}>
            Students
          </Menu.Item>
          <Menu.Item key="5" icon={<IdcardOutlined />}>
            Staff
          </Menu.Item>
          <Menu.Item key="finance" icon={<DollarOutlined />}>
            Finance
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Content className="dashboard-content">
          {renderContent()} {/* Dynamically render the content */}
        </Content>
      </Layout>
    </Layout>
  );
}
