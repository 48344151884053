import React, { useState, useEffect } from 'react';
import { Table, Button, Dropdown, Menu, message, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import axios from 'axios';
import './Finance.scss';

export default function Finance() {
  const [selectedMonth, setSelectedMonth] = useState('December');
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString());
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June', 
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const generateYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear + 1; i >= currentYear - 5; i--) {
      years.push(i.toString());
    }
    return years;
  };

  const handleMonthClick = (e) => {
    setSelectedMonth(e.key);
  };

  const handleYearClick = (e) => {
    setSelectedYear(e.key);
  };

  const monthMenu = (
    <Menu onClick={handleMonthClick}>
      {months.map((month) => (
        <Menu.Item key={month}>{month}</Menu.Item>
      ))}
    </Menu>
  );

  const yearMenu = (
    <Menu onClick={handleYearClick}>
      {generateYears().map((year) => (
        <Menu.Item key={year}>{year}</Menu.Item>
      ))}
    </Menu>
  );

  const columns = [
    { title: 'Parent Name', dataIndex: 'parentName', key: 'parentName' },
    { title: 'Child Name', dataIndex: 'childName', key: 'childName' },
    { title: 'Parent Fee', dataIndex: 'parentFee', key: 'parentFee' },
    { title: 'Billed Days', dataIndex: 'billedDays', key: 'billedDays' },
    { title: 'Total Fee', dataIndex: 'totalFee', key: 'totalFee' },
    { title: 'Invoice Number', dataIndex: 'invoiceNumber', key: 'invoiceNumber' },
  ];

  const fetchData = async (month, year) => {
    setLoading(true);
    try {
      const response = await axios.get('https://api.montessorichildrenshouse.ca/invoices', {
        params: { month, year },
      });
      const formattedData = response.data.map((item, index) => ({
        key: index + 1,
        parentName: item.parent_name,
        childName: `${item.student_first_name} ${item.student_last_name}`,
        parentFee: `$${item.parent_fee}`,
        billedDays: item.billed_days,
        totalFee: `$${item.total_monthly_fee}`,
        invoiceNumber: item.invoice_number,
      }));
      setData(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('Failed to load data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const shortMonth = selectedMonth.substring(0, 3).toUpperCase(); // Convert month to short form
    fetchData(shortMonth, selectedYear);
  }, [selectedMonth, selectedYear]);

  return (
    <div className="finance-container">
      <div className="table-header">
        <h2 className="table-title">Finance</h2>
        <Space>
          <Dropdown overlay={monthMenu} trigger={['click']}>
            <Button type="primary" style={{ backgroundColor: '#C9150E', borderColor: '#C9150E' }}>
              {selectedMonth} <DownOutlined />
            </Button>
          </Dropdown>
          <Dropdown overlay={yearMenu} trigger={['click']}>
            <Button type="primary" style={{ backgroundColor: '#C9150E', borderColor: '#C9150E' }}>
              {selectedYear} <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={{ pageSize: 5 }}
        bordered
        className="finance-table"
        scroll={{ x: 'max-content' }}
      />
    </div>
  );
}
